/**
 * 
 * Pinia Store: pénznem váltás modal ablak allapota
 * 
 * TODO: átnevezni modalcurrency -re
 * 
*/

export const useCurrencyModalStore = defineStore('currencymodal', {
  state: () => ({
    currencymodal: false,
    // currencymodaltitle: false,
  }),
  getters: {
    getCurrencyModal(): boolean {
      return this.currencymodal
    },
  },
  actions: {
    openCurrencyModal() {
      this.currencymodal = true;
    },
    closeCurrencyModal() {
      this.currencymodal = false;
    },
    // showCurrencyModal(title: string) {
    //   this.title = title
    // },
    switchCurrencyModalStatus() {
      this.currencymodal = !this.currencymodal;
    },
  },
});
